<template>
  <div class="app-action" :style="style">
    <div v-if="false" class="my-8">
      <v-btn color="primary" fab large>
        <IconChat />
      </v-btn>
    </div>
    <div>
      <v-btn
        v-if="$store.getters['authModule/getAuthenticationStatus']"
        color="white"
        fab
        large
        @click="$emit('showCallMe')"
      >
        <IconCallMe />
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAction",
  data() {
    return {
      callMeDialog: false,
    };
  },
  components: {
    IconChat: () => import("~cp/Icons/IconChat"),
    IconCallMe: () => import("~cp/Icons/IconCallMe"),
  },
  computed: {
    style() {
      return this.$route.name === "LoginPage" ||
        this.$route.name === "ForgotPassword" ||
        this.$route.name === "ResetPassword"
        ? {
            right: "20px",
            bottom: "20px",
          }
        : null;
    },
  },
};
</script>

<style src="./AppAction.scss" lang="scss" scoped></style>
